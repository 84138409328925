@import './../../../../scss/theme-bootstrap';

.geo-drawer {
  background: $color-pink-2;
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__region-icon {
    display: none;
    margin-right: 15px;
    img {
      width: 35px;
    }
  }
  &__text {
    p {
      margin: 0px;
    }
    &--geo-missing {
      display: none;
    }
  }
  &__link {
    text-transform: none;
    color: $color-pink-accent;
    cursor: pointer;
    &:after {
      content: ' | ';
      color: $color-black;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &--geo-missing {
      display: none;
    }
  }
  &.geo-missing {
    .geo-drawer__region-icon {
      display: none;
    }
    .geo-drawer__text {
      &--geo-missing {
        display: block;
      }
      &--geo-found {
        display: none;
      }
    }
    .geo-drawer__link {
      &--geo-found {
        display: none;
      }
      &--geo-missing {
        display: inline;
      }
    }
  }
}

#colorbox.geo-drawer-cbox {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.show {
    opacity: 1;
  }
  #cboxWrapper {
    background: $color-pink-2;
  }
  #cboxLoadedContent {
    background: $color-pink-2;
  }
}
